import React from 'react';

import localServerApi from '@/api/localServer';

import ErrorContainer from '@/components/error-handling/top-level/ErrorContainer';

/**
 * Catches react errors thrown synchronously on render and lifecycle methods
 * log them to our logging service.
 */
class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
    error: null,
  };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error, info) {
    // Notifies logrocket
    console.error('Top level error: ', info, error);

    // Notifies our app server so we can also add this to our logs
    localServerApi.global.logError({
      message: error?.message,
      cause: error?.cause,
      stack: error?.stack?.substring?.(0, 500),
      componentStack: info?.componentStack?.substring?.(0, 500),
    });
  }

  render() {
    const { hasError, error } = this.state;

    if (hasError) {
      /*
       * Handles unexpected errors and errors loading the app core data
       * that without it the app can't really do much.
       *
       * Renders a branch component tree and not the main tree.
       * None of the app contexts will be rendered in here.
       */
      return <ErrorContainer error={error} />;
    }

    // Main component tree
    return this.props.children;
  }
}

export default ErrorBoundary;
