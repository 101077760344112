import createColor from 'color';
import { rgba } from '@/styles/js/utils/colors';

export const defaultColors = {
  primary: '#4760ff',
  accent: '#140c40',
  text: '#140c40',
  slate: '#8982b3',
  dark: '#140c40',
  red: '#e44439',
  white: 'white',
  black: 'black',
  orange: 'orange',
  green: 'green',
  gray: 'gray',
  rule: rgba('#140c40', 0.12),
  ruleMedium: rgba('#140c40', 0.18),
  ruleDark: rgba('#140c40', 0.55),
  ruleReverse: rgba('white', 0.3),
  faint: rgba('#aebddb', 0.12),
  iconsLight: rgba('#140c40', 0.6),
};

const getAppColors = (customColors = {}) => {
  let currentColors = { ...defaultColors, ...customColors };

  const primary = createColor(currentColors.primary);
  const accent = createColor(currentColors.accent);
  const text = createColor(currentColors.text);
  const white = createColor('white');

  return {
    ...currentColors,
    primaryDark: primary.darken(0.25).hex(),
    primaryTint: primary.rgb().alpha(0.85).string(),
    primaryTintLight: primary.rgb().alpha(0.6).string(),
    accentWhitened: accent.mix(white, 0.85).hex(),
    accentTransparent: accent.rgb().alpha(0.2).string(),
    accentTransparentDark: accent.rgb().alpha(0.6).string(),
    accentTransparentLight: accent.rgb().alpha(0.08).string(),
    accentFaint: accent.lighten(0.4).alpha(0.04).rgb().string(),
    textLight: text.rgb().alpha(0.6).string(),
    textBody: text.rgb().alpha(0.85).string(),
    textDisabled: text.rgb().alpha(0.4).string(),
  };
};

export default getAppColors;
