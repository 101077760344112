import 'lazysizes/lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';
// Change to require if we ever implement native SSR again
import 'lazysizes/plugins/bgset/ls.bgset';
import 'lazysizes/plugins/respimg/ls.respimg';
import debounce from 'p-debounce';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import Text from '@/components/text-headings/Text';

import {
  getCloudflareImageURL,
  imagePlaceholderForTransform,
  imageRatioForTransform,
} from '@/lib/images';

import eases from '@/styles/js/utils/eases';

/**
 * Styled Image
 * ---
 * A responsive image element.
 *
 * Optional props
 * - `greyScale`
 */
export const StyledImage = styled.div`
  width: 100%;

  ${({ greyScale }) =>
    greyScale &&
    css`
      filter: grayscale(1);
    `}
`;

const StyledImageInner = styled.div`
  height: 0;
  overflow: hidden;
  position: relative;

  img {
    min-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    transition: opacity 0.6s ${eases['outQuad']};
    will-change: opacity;
    object-fit: cover;
    width: 100%;
    height: 100%;
    object-position: center center;
  }

  img.lazyloaded {
    opacity: 1;
  }
`;

const StyledFallback = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 10;
`;

/**
 * Styled Image Fallback Retry
 * ---
 * Optional props
 * - `inline`
 */
const StyledFallbackRetry = styled.span`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary};

  ${({ inline }) =>
    inline &&
    css`
      display: flex;
    `}
`;

/**
 * Image Fallback Retry button
 * ---
 * Optional props
 * - `inline`
 * - `onClick`
 */
export const ImageFallbackRetryPlaceholder = ({ children, ...props }) => (
  <StyledFallbackRetry {...props}>{children}</StyledFallbackRetry>
);

ImageFallbackRetryPlaceholder.propTypes = {
  inline: PropTypes.bool,
  onClick: PropTypes.func,
};

const debouncedFunction = debounce((callback, ...args) => {
  callback(...args);
}, 50);

/**
 * Outputs the optimal image based on viewport size,
 * resolution, and other criteria.
 */
const Image = ({
  src,
  alt,
  transform,
  renderErrorFallback,
  greyScale,
  onLoad,
  ...props
}) => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    return () => {
      setLoaded(false);
      setError(false);
    };
  }, []);

  const retry = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setError(false);
  };

  const handleLoad = useCallback(
    (...args) => {
      if (!loaded) {
        if (onLoad && typeof onLoad === 'function') {
          debouncedFunction(onLoad, ...args);
        }
        setLoaded(true);
      }
    },
    [loaded, onLoad],
  );

  if (!src) {
    return null;
  }

  const pixelDensity = 2;
  const sizeIncrement = 300;
  const maxSize = Math.min(window.innerWidth * pixelDensity, 2500);

  let currentSize = sizeIncrement;
  let sizes = [];

  const transformRatio = imageRatioForTransform(transform);
  const ratio = transformRatio.height / transformRatio.width;

  while (currentSize <= maxSize) {
    const scaledWidth = Math.round(currentSize);
    const scaledHeight = Math.round(scaledWidth * ratio);
    const imageURL = getCloudflareImageURL(src, {
      quality: 65,
      fit: 'crop',
      width: scaledWidth,
      height: scaledHeight,
    });

    sizes.push(`${imageURL} ${Math.round(currentSize / pixelDensity)}w`);
    currentSize += sizeIncrement;
  }

  return (
    <StyledImage {...props} greyScale={greyScale}>
      <StyledImageInner
        style={{
          paddingTop: ratio * 100 - 0.5 + '%',
        }}
      >
        {!error ? (
          <img
            onLoad={handleLoad}
            onError={() => setError(true)}
            alt={alt}
            src=""
            srcSet={`data:image/gif;base64,${imagePlaceholderForTransform(
              transform,
            )}`}
            data-srcset={sizes.join(', ')}
            data-sizes="auto"
            className="lazyload"
          />
        ) : (
          <StyledFallback>
            {typeof renderErrorFallback === 'function' ? (
              renderErrorFallback({
                reload: retry,
              })
            ) : (
              <>
                <Text>
                  <p>Failed to load this image</p>
                </Text>

                <Text>
                  <p>
                    click{' '}
                    <ImageFallbackRetryPlaceholder onClick={retry}>
                      here
                    </ImageFallbackRetryPlaceholder>{' '}
                    to retry.
                  </p>
                </Text>
              </>
            )}
          </StyledFallback>
        )}
      </StyledImageInner>
    </StyledImage>
  );
};

Image.defaultProps = {
  alt: '',
  transform: 'space',
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
  transform: PropTypes.oneOf(['square', 'space', 'spaceShort']).isRequired,
  alt: PropTypes.string,
  onLoad: PropTypes.func,
  greyScale: PropTypes.bool,
  renderErrorFallback: PropTypes.func,
};

export default Image;
