import styled, { css } from 'styled-components/macro';

import DayPickerComponent from 'react-day-picker';

import typeStyle from '@/styles/js/fonts/typeStyles';
import fluidSize from '@/styles/js/utils/fluidSize';
import { rgba } from '@/styles/js/utils/colors';
import { em } from '@/styles/js/utils/units';
import eases from '@/styles/js/utils/eases';

import { Icon as StyledIcon } from '@/components/graphics/Icon.styles';
import { absolute as pseudoAbsolute } from '@/styles/js/utils/pseudo';

// TODO fix references to .DayPicker class

const dayPseudoSize = '3.4rem';
const dayPseudo = css`
  ${pseudoAbsolute}
  left: 50%;
  margin-left: calc((${dayPseudoSize} / 2) * -1);
  top: 50%;
  margin-top: calc((${dayPseudoSize} / 2) * -1);
  border-radius: 100%;
  width: ${dayPseudoSize};
  height: ${dayPseudoSize};
  line-height: ${dayPseudoSize};
`;

export const FormDatePicker = styled.div`
  position: relative;
`;

export const Picker = styled.div`
  ${({ theme, $withInput }) => $withInput && css`
    ${fluidSize('content')};

    z-index: 1;
    width: 100%;
    background: ${theme.colors.white};
    position: absolute;
    opacity: 0;
    transition: 0.3s ${eases.outQuint};
    transition-property: opacity, transform;
    will-change: opacity, transform;

    padding-top: 2rem;
    border: 1px solid rgba(20,12,64,0.18);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    top: calc(100% - 4px);
  `}

  ${({ $expanded }) => $expanded && css`
    opacity: 1;
    transform: translateY(0);
  `}

  ${({ theme, $selectionDisabled }) => $selectionDisabled && css`
    &&& .DayPicker-Day.DayPicker-Day--selected {
      text-decoration: line-through;
      background: ${theme.colors.accentTransparentLight};
      color: ${rgba(theme.colors.text, 0.25)};
    }

    &&& .DayPicker-Day.DayPicker-Day--selected.DayPicker-Day--invalid {
      text-decoration: line-through;
      background: ${rgba(theme.colors.red, 0.85)};
      color: ${rgba(theme.colors.text, 0.25)};
    }
  `}
`;

/**
 * Custom Nav Bar
 * ---
 * Custom styling for the react-day-picker component's navigation bar.
 */
export const CustomNavBar = styled.nav`
  position: relative;
  color: ${({ theme }) => theme.colors.accent};
  z-index: 1;
`;

export const CustomNavBarPrev = styled.span`
  left: 4.2%;
`;

export const CustomNavBarNext = styled.span`
  right: 4.2%;
`;

/**
 * React Day Picker
 * ---
 * Custom styling for the react-day-picker component.
 */
export const DayPicker = styled(DayPickerComponent)`
  &.DayPicker {
    display: block;
  }

  &.DayPicker-wrapper:focus {
    outline: none;
  }

  // Custom Nav Bar
  
  ${CustomNavBarPrev},
  ${CustomNavBarNext} {
    cursor: pointer;
    position: absolute;
    top: 0;
  }

  // Month

  .DayPicker-Month {
    margin: 0;
    width: 100%;
    margin-top: 9.5%;
  }

  // Caption

  .DayPicker-Caption {
    display: block;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;

    > div {
      ${typeStyle('h4')};
      color: ${({ theme }) => theme.colors.text};
      text-align: center;
    }
  }

  // Day

  .DayPicker-Weekday,
  .DayPicker-Day {
    line-height: 1;
    padding-top: 4%;
    padding-bottom: 4%;
  }

  .DayPicker-Weekday {
    ${typeStyle('mini')};
    color: ${({ theme }) => theme.colors.textLight};
  }

  .DayPicker-Day {
    ${typeStyle('body')};
    color: ${({ theme }) => theme.colors.text};
    position: relative;
    overflow: hidden;
  }
  
  .DayPicker-Day--outside {
    visibility: hidden;
  }

  .DayPicker-Day:focus,
  .DayPicker-Day--today {
    outline: none;

    &:before {
      ${dayPseudo}
      border: 1px solid ${({ theme }) => theme.colors.ruleMedium};
    }
  }

  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(
      .DayPicker-Day--selected
    ):not(.DayPicker-Day--outside):hover {
    background-color: transparent;

    &:before {
      ${dayPseudo}
      background-color: ${({ theme }) => theme.colors.faint};
      border: 2px solid ${({ theme }) => theme.colors.primary};
    }
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(
    .DayPicker-Day--outside
  ),
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(
      .DayPicker-Day--outside
    ):hover {
    background-color: transparent;
    color: ${({ theme }) => theme.colors.white};

    &:before {
      border: 2px solid ${({ theme }) => theme.colors.primary};
      z-index: -1;

      @supports (mix-blend-mode: multiply) {
        border: none;
      }
    }
  }

  .DayPicker-Day--disabled {
    color: ${({ theme }) => rgba(theme.colors.text, 0.25)};
  }
  
  .DayPicker-Day:focus {
    outline: none;
  }

  ${({ theme, $range }) => $range && css`
    .DayPicker-Day--selected:not(.DayPicker-Day--start):not(
      .DayPicker-Day--end
    ):not(.DayPicker-Day--outside):not(.DayPicker-Day--singleDay) {
      background-color: ${theme.colors.primaryTint};
      color: white;

      &:before {
        background-color: transparent;
      }
    }

    .DayPicker-Day:not(.DayPicker-Day--start):not(.DayPicker-Day--end) {
      border-radius: 0;
    }

    .DayPicker-Day.DayPicker-Day--selected.DayPicker-Day--start,
    .DayPicker-Day.DayPicker-Day--selected.DayPicker-Day--start:hover {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      background-color: ${theme.colors.primary};

      &:before {
        background-color: transparent;
      }
    }

    .DayPicker-Day.DayPicker-Day--selected.DayPicker-Day--end,
    .DayPicker-Day.DayPicker-Day--selected.DayPicker-Day--end:hover {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      background-color: ${theme.colors.primary};

      &:before {
        background-color: transparent;
      }
    }

    &&& .DayPicker-Day.DayPicker-Day--selected.DayPicker-Day--disabled {
      text-decoration: line-through;
      background: ${theme.colors.accentTransparentLight};
      color: ${rgba(theme.colors.text, 0.25)};
    }

    &&& .DayPicker-Day.DayPicker-Day--invalid.DayPicker-Day--selected {
      text-decoration: line-through;
      background: ${rgba(theme.colors.red, 0.85)};
      color: ${rgba(theme.colors.text, 0.25)};
    }
  `}
`;

export const DateInput = styled.div`
  background: transparent;
  appearance: none;
  border: 1px solid ${({ theme }) => theme.colors.accentTransparentDark};
  border-radius: 0.4rem;
  line-height: 1.8rem;
  padding: ${em(11, 16)} ${em(14, 16)} ${em(13, 16)};
  max-width: 40rem;
  display: block;
  padding: 1rem;

  cursor: pointer;
  position: relative;

  & > ${StyledIcon} {
    position: absolute;
    color: ${({ theme }) => theme.colors.accent};
    right: 1rem;
    top: 0;
    bottom: 0;
    margin: auto 0;
  }

  ${({ $fullWidth }) => $fullWidth && css`
    width: 100%;
    max-width: none;
  `}

  ${({ theme, $expanded }) => $expanded && css`
    border-color: ${theme.colors.ruleMedium};
    color: ${theme.colors.textLight};

    > ${StyledIcon} {
      color: ${theme.colors.iconsLight};
    }
  `}
`;

export const Day = styled.span`
  ${({ theme, $active }) => $active && css`
    width: 3.4rem;
    height: 3.4rem;
    border-radius: 100%;
    background-color: ${theme.colors.primary};
    position: absolute;
    margin-left: -1.7rem;
    margin-top: -1.7rem;
    line-height: 3.4rem;
  `}
`;
