import { Component, createContext, useContext } from 'react';

import deskpassApi from '@/api/deskpass';
import localServerApi from '@/api/localServer';

import { withPageVisibilityContext } from '@/context/PageVisibility';
import { withSessionContext } from '@/context/Session';

import { compose, consumerToHOC } from '@/lib/hoc';
import { identifyUser, initializeLogRocket } from '@/lib/logRocket';
import { getNewRoomBookingMessages } from '@/lib/reservationHelpers';

const Context = createContext({});

// Export a dummy function and later on mutate it
export let logout = () => null;

export const initialState = {
  // Set to true when acknowledging the user is not logged or after
  // loading the current logged user.
  ready: false,
  // Set to true while the authentication process is happening
  authenticating: false,
  // Wether or not the user is logged in
  authenticated: false,
  // This will only be used during signup step while user
  // instance is not ready in the context
  invitedUserEmail: null,
  // This will only be used during signup step while user
  // instance is not ready in the context
  temporaryTeamOrganization: {},
  // When a team invite fails due to expiration or something else
  teamInviteError: false,
  // Logged user DB instance
  user: {},
  // TODO consider moving inside user on the /self API
  // User active Stripe coupon
  activeCoupon: {},
  // List of message updates tied to the
  // booking approval feature
  newRoomBookingMessages: [],
  // Flag to tell if the above is being loaded
  hourlyBookingMessageUpdatesLoading: false,
  // should show SignupLoginModal
  showSignupLoginModal: false,
};

class Provider extends Component {
  state = {
    ...initialState,
  };

  // Poller interval
  roomMessageUpdatesPollerInterval = null;

  constructor(props) {
    super(props);

    // Prepare logout function to be used outside react context
    logout = () => {
      this.logout();
    };
  }

  async componentDidMount() {
    if (this.props.sessionContext.active) {
      try {
        // Load the user now we can be sure they have an active session then flag
        // user as authenticated and ready if it succeeds to fetch user data
        await this.loadUser();

        const { user } = this.state;

        initializeLogRocket();
        identifyUser(user.id, user.email);

        // After authenticating start to poll for unread messages
        this.setState({ authenticated: true, ready: true }, this.onReady);
      } catch (err) {
        console.error('Error loading user', err);
        logout();
      }
    } else {
      // No token set means the user doesn't have an active session
      // So we assume it's not logged and ready the context state.
      this.setState({ ready: true }, this.onReady);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      !prevProps.pageVisibilityContext.visible &&
      this.props.pageVisibilityContext.visible
    ) {
      this.onPageVisible();
    }
  }

  render() {
    // Everything in here will be expose to the context consumer
    let context = {
      // Expose every state variable
      ...this.state,
      // User Actions
      updateUser: this.updateUser,
      loadUser: this.loadUser,
      // Auth
      login: this.login,
      logout: this.logout,
      // Team
      teamUser: this.isTeamUser(),
      getTeamOrganization: this.getTeamOrganization,
      getTeamCustomization: this.getTeamCustomization,
      teamOrganization: this.getTeamOrganization(),
      teamCustomization: this.getTeamCustomization(),
      isCustomized: this.isCustomized,
      loadTeamInvitation: this.loadTeamInvitation,
      // SignupLoginModal
      openSignupLoginModal: this.openSignupLoginModal,
      closeSignupLoginModal: this.closeSignupLoginModal,
    };

    return (
      <Context.Provider value={context}>{this.props.children}</Context.Provider>
    );
  }

  /**
   * Attempt to update the user (self) data.
   */
  updateUser = (userPayload = {}, noReload = false) => {
    // TODO consider adding the updated fields or the entire
    // user entity to the POST operation
    return deskpassApi.user
      .updateSelf(userPayload)
      .then(() => (noReload ? null : this.loadUser()));
  };

  /**
   * Attempt to load in the user (self).
   */
  loadUser = async () => {
    const user = await deskpassApi.user.getSelf();

    this.setState({ user });

    const [activeCoupon = {}] = await Promise.all([
      deskpassApi.user.getActiveCoupon(),
      this.loadRoomBookingMessageUpdates(),
    ]);

    this.setState({ activeCoupon });
  };

  // Starts the polling for updates again
  onPageVisible = () => {
    this.pollRoomMessageUpdates();
  };

  // Set up interval to check for new room booking messages.
  // Yeah, some people consider this kind of thing to be poor form.
  // I like to think of it as 'websockets 0.1'.
  // Nah, the real issue here is we're currently in the middle of a major app
  // refactoring and this entire file is going away, so I don't want to put a
  // bunch of websocket stuff in place until we're done with that and all the
  // new state management is in place. So for now--old-fashioned polling.
  //
  // Only polls the API if the user is looking at the app tab.
  pollRoomMessageUpdates = () => {
    this.roomMessageUpdatesPollerInterval = setTimeout(() => {
      if (
        this.state.authenticated &&
        this.props.pageVisibilityContext.visible
      ) {
        this.loadRoomBookingMessageUpdates();
      }
    }, 1000 * 60);
  };

  /**
   * With the booking approval feature some hourly
   * bookings will have an exchnge of messages
   */
  loadRoomBookingMessageUpdates = () => {
    this.setState({ hourlyBookingMessageUpdatesLoading: true });

    if (this.roomMessageUpdatesPollerInterval) {
      clearTimeout(this.roomMessageUpdatesPollerInterval);
    }

    return new Promise((resolve) => {
      return Promise.all([
        deskpassApi.user.getHourlyBookingMessageUpdates(),
        deskpassApi.user.getOfficeBookingMessageUpdates(),
      ])
        .then(([hourlyBookingMessageUpdates, officeBookingMessageUpdates]) => {
          return getNewRoomBookingMessages([
            ...hourlyBookingMessageUpdates,
            ...officeBookingMessageUpdates,
          ]).then((newRoomBookingMessages) => {
            this.setState(
              {
                newRoomBookingMessages,
                hourlyBookingMessageUpdatesLoading: false,
              },
              () => {
                resolve(this.state.newRoomBookingMessages);
              },
            );
          });
        })
        .finally(() => {
          this.pollRoomMessageUpdates();
        });
    });
  };

  // Auth //

  /**
   * Attempt to log the user in.
   */
  login = async (email, password, dpCsrfToken) => {
    this.setState({ authenticating: true });

    try {
      // Attempt to process the basic login
      await localServerApi.auth.login(email, password, dpCsrfToken);

      // If that went well (if it didn't it will just error out), retrieve the
      // auth token which will now be set in the session on the app server
      await this.props.sessionContext.retrieveAuthToken();

      // Then attempt to load the user now that required auth is set
      const user = await this.loadUser();

      // Great success!
      return new Promise((resolve) => {
        resolve(user);
        this.closeSignupLoginModal();
        this.setState({
          authenticating: false,
          authenticated: true,
        });
      });
    } catch (err) {
      return new Promise((_, reject) => {
        this.setState(
          {
            authenticating: false,
            authenticated: false,
          },
          () => {
            // After all state is ready reject the login promise
            reject(err);
          },
        );
      });
    }
  };

  /**
   * Attempt to log the user out.
   */
  logout = async () => {
    // Blow out access token on the server side
    await localServerApi.auth.logout();
    this.props.sessionContext.clearSession();

    return new Promise((resolve) => {
      this.setState(
        {
          user: {},
          activeCoupon: {},
          authenticated: false,
          // this can be called on a page refresh so
          // it's important to make sure its set.
          ready: true,
        },
        () => {
          // After all state is updated, resolve the logout promise
          return resolve();
        },
      );
    });
  };

  // Teams //

  /*
   * Checks if loaded user is a team member
   */
  isTeamUser = () => {
    const { user, invitedUserEmail } = this.state;

    // Return true if we're dealing with team Signup
    if (invitedUserEmail) {
      return true;
    }

    return !!(user && user.isTeamUser);
  };

  /*
   * This is important so that team signup gets team colors as soon as possible
   */
  getTeamOrganization = () => {
    const { user, temporaryTeamOrganization } = this.state;

    if (user.teamOrganization) {
      return user.teamOrganization;
    }

    if (temporaryTeamOrganization) {
      return temporaryTeamOrganization;
    }

    return {};
  };

  /*
   * Returns a team customization object or an empty object.
   */
  getTeamCustomization = () => {
    return (this.getTeamOrganization() || {}).customization || {};
  };

  /*
   * This is important so that team signup gets team colors as soon as possible
   */
  loadTeamInvitation = (hash) => {
    return new Promise((resolve) => {
      deskpassApi.user
        .getTeamInvitation(hash)
        .then(({ email, firstName, lastName, teamOrganization }) => {
          this.setState(
            {
              invitedUserFirstName: firstName,
              invitedUserLastName: lastName,
              invitedUserEmail: email,
              temporaryTeamOrganization: teamOrganization,
            },
            () => {
              resolve({ email, firstName, lastName, teamOrganization });
            },
          );
        })
        .catch(() => {
          this.setState({ teamInviteError: true });
        });
    });
  };

  // SignupLoginModal //

  openSignupLoginModal = () => {
    if (!this.state.authenticated) {
      this.setState({ showSignupLoginModal: true });
    }
  };

  closeSignupLoginModal = () => {
    this.setState({ showSignupLoginModal: false });
  };
}

export const UserProvider = compose(
  withSessionContext,
  withPageVisibilityContext,
)(Provider);
export const withUserContext = consumerToHOC(Context.Consumer, 'userContext');
export const useUserContext = () => useContext(Context);
export default Context;
