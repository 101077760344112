/**
 * App config vars. Add to as needed.
 *
 * In order to access env vars from process client-side
 * the envs should have the prefix REACT_APP as stated
 * by create react app, unless the variables come from
 * our server.
 */
import { stripTrailingSlash } from '@/lib/stringHelpers';

// Grab server config if it exists
const SERVER_CONFIG = window.SERVER_CONFIG || {};

const NODE_ENV =
  SERVER_CONFIG.NODE_ENV || process.env.NODE_ENV || 'development';

const MAINTENANCE_MODE =
  SERVER_CONFIG.MAINTENANCE_MODE === 'true' ||
  process.env.REACT_APP_MAINTENANCE_MODE === 'true';

const MARKETING_SITE_URL =
  NODE_ENV === 'production'
    ? 'https://www.deskpass.com'
    : 'https://marketing-staging.deskpass.com';

const GOOGLE_MAPS_API_KEY =
  SERVER_CONFIG.GOOGLE_MAPS_API_KEY ||
  process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const GOOGLE_MAPS_MAP_ID =
  SERVER_CONFIG.GOOGLE_MAPS_MAP_ID || process.env.REACT_APP_GOOGLE_MAPS_MAP_ID;

let DEFAULT_CONFIG = {};

/*
 * In dev mode we want some defaults but in the other environments
 * we want to provide the variables explicitly.
 *
 * Also with this NODE_ENV check, the compiler will remove these defaults
 * from the bundle.
 */
if (NODE_ENV === 'development') {
  DEFAULT_CONFIG = {
    // Our envs
    NODE_ENV,
    ANALYTICS_TRACKING_DISABLED: false,

    // Only set here for development convenience
    SERVER_URL: stripTrailingSlash(
      process.env.REACT_APP_SERVER_URL ||
        `http://localhost:${process.env.PORT || 3000}`,
    ),
    API_URL: stripTrailingSlash(process.env.REACT_APP_API_URL),
    DASH_URL: stripTrailingSlash(process.env.REACT_APP_DASH_URL),

    // Defaults for third party tokens
    STRIPE_KEY: process.env.REACT_APP_STRIPE_KEY,

    // Hubspot vars
    HUBSPOT_APP_ID: process.env.REACT_APP_HUBSPOT_APP_ID,
    HUBSPOT_URL: stripTrailingSlash(
      process.env.REACT_APP_HUBSPOT_URL || 'https://hs.deskpass.com',
    ),
    DISABLE_HUBSPOT: process.env.REACT_APP_DISABLE_HUBSPOT
      ? !!+process.env.REACT_APP_DISABLE_HUBSPOT
      : 1,

    // Logrocket
    // Enabling this will cause LogRocket to log things in the dev console
    LOGROCKET_LOCAL: process.env.REACT_APP_LOGROCKET_LOCAL === 'true',
    LOGROCKET_ID: process.env.REACT_APP_LOGROCKET_ID,

    // Ip Geolocation API Key
    IP_GEOLOCATION_KEY: process.env.REACT_APP_IP_GEOLOCATION_KEY,
  };
}

const config = {
  // Fallback value of how often we should log logrocket sessions
  LOG_ROCKET_SESSION_PERCENTAGE: 25,

  MARKETING_SITE_URL,

  // Default values for env vars in case they're not provided from the server
  ...DEFAULT_CONFIG,

  // Replace defaults with the actual values from the server
  ...SERVER_CONFIG,

  // Makes client-side navigation logs more verbose
  DEBUG_NAVIGATION: process.env.REACT_APP_DEBUG_NAVIGATION || false,

  // Makes i18n logs more verbose
  DEBUG_I18N: process.env.REACT_APP_DEBUG_I18N || false,

  // Default radius used to load markers in map viewport if no other is provided
  DEFAULT_RADIUS: 50,

  // How far in advance a daily booking can be created from the current day
  MAX_DAILY_BOOKING_MONTHS: 6,

  GOOGLE_MAPS_API_KEY,
  GOOGLE_MAPS_MAP_ID,

  INTERCOM_APP_ID: 'rvsrj6tf',

  // These seem to change somewhat often, hence being a config var
  SUPPORT_HOURS: '7:30am-5:30pm EST',

  STRIPE_IDENTITY_PRIVACY_POLICY_URL:
    'https://support.stripe.com/questions/managing-your-id-verification-information',

  // @TODO: Remove once we fully migrate over to Hubspot
  ACTIVE_CRM: 'hubspot',

  MAINTENANCE_MODE,
};

export default config;
