import LogRocket from 'logrocket';

import config from '@/lib/config';
import { getCookieValue } from '@/lib/cookie';
import { randomIntFromInterval } from '@/lib/mathHelpers';
import { isPrerender } from '@/lib/prerender';

const { LOG_ROCKET_SESSION_PERCENTAGE, LOGROCKET_LOCAL, NODE_ENV } = config;

const isAuth = getCookieValue('dpa.auth') === 'true';
const random = randomIntFromInterval(1, 100);
const shouldTrackAnonymous = random <= LOG_ROCKET_SESSION_PERCENTAGE;

let logrocketEnabled = !isPrerender();

if (NODE_ENV === 'development' && !LOGROCKET_LOCAL) {
  logrocketEnabled = false;
}

export function identifyUser(id, email) {
  if (logrocketEnabled && LogRocket._isInitialized) {
    LogRocket.identify(id, { email });
  }
}

export function initializeLogRocket() {
  if (logrocketEnabled && !LogRocket._isInitialized) {
    LogRocket.init(config.LOGROCKET_ID);
  }
}

if (isAuth || shouldTrackAnonymous) {
  initializeLogRocket();
}
