import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from 'body-scroll-lock';
import classnames from 'classnames';
import debounce from 'p-debounce';
import PropTypes from 'prop-types';
import React, {
  cloneElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Modal as ModalOverlay } from 'react-overlays';
import Transition, {
  ENTERED,
  ENTERING,
  EXITED,
  EXITING,
} from 'react-transition-group/Transition';

import LoadingContent from '@/components/helpers/LoadingContent';
import { StatusBarArea } from '@/components/helpers/MobileDeviceArea';
import ModalContent from '@/components/modals/ModalContent';

import useMapOldDashedProps from '@/hooks/useMapOldDashedProps';

import { childrenType } from '@/lib/props';
import { addHTMLClass, removeHTMLClass } from '@/lib/utility';

import * as Styled from './index.styles';

const dashedModifiers = [
  'origin-right',
  'bg-warning',
  'no-backdrop',
  'bg-transparent-dark',
  'bg-transparent-white',
  'bg-transparent',
  'fit-to-content',
  'wide',
  'no-padding',
];

/**
 * Wraps content that appears on top of everything else onscreen.
 */
const Modal = ({
  children,
  renderNav,
  renderFooter,
  label,
  show,
  type,
  containerId,
  onExitTrigger,
  onClose,
  onEntering,
  onExiting,
  loading,
  ...dashedNamedProps
}) => {
  const modifiers = useMapOldDashedProps(dashedNamedProps, dashedModifiers);

  const containerEl = useMemo(() => {
    return typeof document !== 'undefined'
      ? document.getElementById(containerId)
      : null;
  }, [containerId]);

  const modalRef = useRef();
  const containerRef = useRef(containerEl);

  const [isInitialScrollPosition, setIsInitialScrollPosition] = useState(
    () => true,
  );

  /*
   * Create once a state that won't trigger re-renders on change
   * and will exist until the component unmounts.
   */
  useEffect(() => {
    const container =
      typeof document !== 'undefined'
        ? document.getElementById(containerId)
        : null;

    containerRef.current = container;
  }, [containerId]);

  // Handles Disposal logic
  useEffect(
    () => () => {
      // Make sure to enable body scroll again on unmount
      // cause sometimes you navigate without exiting the modal
      if (modalRef.current) {
        enableBodyScroll(modalRef.current);
      } else {
        clearAllBodyScrollLocks();
      }
    },
    [],
  );

  const onModalScroll = useCallback((e) => {
    const debouncedHandler = debounce((isInitialPosition) => {
      setIsInitialScrollPosition(isInitialPosition);
    }, 100);

    debouncedHandler(e.target.scrollTop === 0);
  }, []);

  const handleEntering = useCallback(() => {
    // Add open class to HTML element so we can modify behavior of root-level
    // elements outside of React such as Hubspot chat widget
    addHTMLClass('modal-open');
    addHTMLClass('hide-chat-widget');

    disableBodyScroll(modalRef.current, { reserveScrollBarGap: true });

    if (typeof onEntering === 'function') {
      onEntering();
    }
  }, [onEntering]);

  const handleExiting = useCallback(() => {
    removeHTMLClass('modal-open');
    removeHTMLClass('hide-chat-widget');

    enableBodyScroll(modalRef.current);

    if (typeof onExiting === 'function') {
      onExiting();
    }
  }, [onExiting]);

  const container = containerRef.current;

  if (!container) {
    return null;
  }

  return (
    <ModalOverlay
      container={container}
      aria-labelledby={label}
      show={show}
      onEntering={handleEntering}
      onExiting={handleExiting}
      onExited={onClose}
      onBackdropClick={onExitTrigger}
      onEscapeKeyDown={onExitTrigger}
      transition={ModalTransition}
      backdropTransition={ModalTransition}
      renderBackdrop={(backdropProps) => (
        <Styled.Backdrop {...backdropProps} hide={!!modifiers.noBackdrop} />
      )}
      renderDialog={(dialogProps) => {
        return (
          <Styled.Modal
            {...modifiers}
            ref={modalRef}
            type={type}
            onScroll={onModalScroll}
          >
            <Styled.Dialog {...dialogProps}>
              <StatusBarArea />

              <Styled.Content>
                {loading && <LoadingContent placement="cover" />}
                <ModalContent
                  isInitialScrollPosition={isInitialScrollPosition}
                  renderNav={renderNav}
                  renderFooter={renderFooter}
                  {...modifiers}
                >
                  {children}
                </ModalContent>
              </Styled.Content>
            </Styled.Dialog>
          </Styled.Modal>
        );
      }}
    />
  );
};

const transitionStyles = {
  [ENTERING]: 'entering',
  [ENTERED]: 'entered',
  [EXITING]: 'exiting',
  [EXITED]: 'exited',
};

const MemoedChild = React.memo(({ state, children }) => {
  const { className: childClass, ...childProps } = children.props;

  const className = classnames(
    childClass || '',
    `is-transition-${transitionStyles[state]}`,
  );

  return cloneElement(children, {
    ...childProps,
    className,
  });
});

const ModalTransition = ({ children, ...otherProps }) => {
  return (
    <Transition {...otherProps} timeout={600}>
      {(state) => <MemoedChild state={state}>{children}</MemoedChild>}
    </Transition>
  );
};

Modal.defaultProps = {
  show: false,
  containerId: 'modal-root',
  type: 'side',
  onExitTrigger: () => {},
};

Modal.propTypes = {
  type: PropTypes.oneOf(['side', 'center', 'full']),
  label: PropTypes.string.isRequired,
  children: childrenType.isRequired,
  renderNav: PropTypes.func,
  renderFooter: PropTypes.func,
  show: PropTypes.bool,
  loading: PropTypes.bool,
  containerId: PropTypes.string,
  onEntering: PropTypes.func,
  onExiting: PropTypes.func,
  onExitTrigger: PropTypes.func,
};

export default Modal;
