export const methods = [
  'get',
  'post',
  'put',
  'patch',
  'delete',
  'head',
  'options',
];
export const bodylessMethods = ['get', 'delete', 'head', 'options'];

export const isIn400StatusRange = (err) => {
  const { status } = err;
  return 400 <= status && status < 500;
};

export const isRedirect = ({ status }) => {
  return Boolean(status) && 300 <= status && status < 400;
};

export const isRequestCancel = (err) => {
  const error = err.error ?? err;
  return error.name === 'AbortError';
};

/*
 * Unfortunately the fetch API currently only consider errors
 * of being AbortError or TypeError wich is a bucket of a lot
 * of generic things so I couldn't find a way for instance
 * to tell a CORS error. So here is just a generic set of things
 * to ignore when checking if we should retry.
 *
 * From fetch docs:
 * https://developer.mozilla.org/en-US/docs/Web/API/fetch#exceptions
 */
export const isTypeError = (err) => {
  const error = err.error ?? err;
  return error.name === 'TypeError';
};
