import { css } from 'styled-components/macro';

import { scaleWithVH } from '@/styles/js/utils/scaling';
import fluidSize from '@/styles/js/utils/fluidSize';
import fluidSizes from '@/lib/fluidSizes';

import mq from '@/styles/js/utils/mq';

import { StyledFormSelect } from '@/components/forms/FormSelect';
import { NavRow } from '@/components/nav/NavRow/index.styles';
import { StyledFormText } from '@/components/forms/FormText';
import { FormLabel as StyledFormLabel } from '@/components/forms/FormLabel.styles';
import { StyledHeading } from '@/components/text-headings/Heading';
import { Button as StyledButton } from '@/components/buttons-links/Button.styles';
import { AmenityList as StyledAmenityList } from '@/components/details/AmenityList.styles';
import { FormDatePicker as StyledFormDatePicker } from '@/components/forms/FormDatePicker/index.styles';

/**
 * Common Spacing
 * ---
 * Handles common spacing between elements
 * @param {String} _baseSpacing - default `undefined`
 */ 
const commonSpacing = (_baseSpacing) => css`

  > * + * {
    margin-top: ${scaleWithVH('2.2rem')};

    ${_baseSpacing && css`
      ${fluidSize(_baseSpacing)}
    `}
  }

  > * + .HourlyOptions,
  > .PageSection + .PageSection {
    // FIXME: attempt to replace classname with PageSection styled component results in circular import issue becuase the component also imports commonSpacing - @ismail
    margin-top: ${scaleWithVH('2.4rem')};
  }

  > ${NavRow} + ${NavRow} {
    margin-top: 0;
  }
`;

/**
  Common Spacing Content

  Handles common spacing between elements in a section of content

  NOTE: Converted sass mixin
*/
const commonSpacingContent = (_baseSpacing = 'content') => css`
  > * + * {
    ${fluidSize(_baseSpacing)};
  }

  // Unique spacing combinations

  > ${StyledHeading} + * {
    ${fluidSize(_baseSpacing, undefined, { adjust: -7 })};
  }

  .Text + * {
    // FIXME: attempt to replace classname with Text styled component results in circular import issue becuase the component also imports commonSpacingContent - @ismail
    ${fluidSize(_baseSpacing, undefined, { adjust: -4 })};
  }

  > * + .Text,
  > ${StyledHeading} + .Text,
  > .Text + .Text,
  > p + p,
  > p + ul,
  > p + ol,
  > ul + p,
  > ul + ul {
    ${fluidSize(_baseSpacing, undefined, { adjust: -12 })};
  }

  > ${StyledHeading} + ${StyledAmenityList} {
    ${fluidSize(_baseSpacing, undefined, { adjust: -14 })};
  }

  > .Text + ${StyledButton} {
    ${fluidSize(_baseSpacing, undefined, { adjust: 1 })};
  }

  ${StyledFormLabel} + * {
    ${fluidSize(_baseSpacing, undefined, { adjust: -10 })};
  }

  > ${StyledFormLabel} + ${StyledFormSelect},
  > ${StyledFormLabel} + ${StyledFormDatePicker} {
    ${fluidSize(_baseSpacing, undefined, { adjust: -11 })};
  }

  > ${StyledFormLabel} + ${StyledFormText} {
    ${fluidSize('none')};
  }

  > .Text + ${StyledFormText} {
    ${fluidSize('none', undefined, { adjust: 10 })};
  }

  > .Text + .Map {
    ${fluidSize(_baseSpacing, undefined, { adjust: 4 })};
  }
`;

const sizeNavSmallHeight = '53px';
const sizeSpaceFiltersSmallHeight = '110px';
const sizeNavLargeHeight = '82px';
const sizeSpaceFiltersLargeHeight = '102px';

/**
 * Loading Content Height
 * ---
 * Common setting for the loading content for a view, which sets
 * the height taking the height of the nav and footer into account.
 * @param {Boolean} small default `true`
 * @param {Boolean} large  default `true`
 */
const loadingContentHeight = (small = true, large = true) => css`
  ${small && css`
    ${mq({ until: 'large' })} {
      min-height: calc(100vh - #{${sizeNavSmallHeight} + ${sizeSpaceFiltersSmallHeight} + ${fluidSizes['lay-m']['max']}});
      min-height: calc(100vh - #{${sizeNavSmallHeight} + ${sizeSpaceFiltersSmallHeight} + ${fluidSizes['lay-m']['max']}} - env(safe-area-inset-bottom));
    }
  `}

  ${large && css`
    ${mq('large')} {
      min-height: calc(100vh - #{${sizeNavLargeHeight} + ${sizeSpaceFiltersLargeHeight} + ${fluidSizes['lay-m']['max']}});
    }
  `}
`;

export { commonSpacing, commonSpacingContent, loadingContentHeight };