// Avatar.js
import styled from 'styled-components';

import { StyledImage } from '@/components/graphics/Image';

const AvatarSize = (props) => {
  if (props.mini) {
    return 'max-width: 2.4rem;';
  } else if (props.small) {
    return 'max-width: 12rem;';
  } else {
    return 'max-width: 40rem;';
  }
};

export const Avatar = styled.div`
  ${AvatarSize}
`;

export const AvatarInner = styled.div`
  background-color: ${({ theme }) => theme.colors.faint};;
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 100%;
  overflow: hidden;
  border-radius: 100%;
  will-change: transform;

  > ${StyledImage} {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

