import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import 'react-day-picker/lib/style.css';

import Icon from '@/components/graphics/Icon';
import LoadingContent from '@/components/helpers/LoadingContent';

import useEvent from '@/hooks/useEvent';

import { formatDateExtended } from '@/lib/dateHelpers';

import * as S from './index.styles';

/**
 * Allows for date selection.
 */
export default function FormDatePicker({
  loading,
  selectionDisabled,
  onMonthChange,
  onDayClick,
  withInput,
  value,
  disabledDays,
  range,
  fullWidth,
  ...remainingProps
}) {
  const [expanded, setExpanded] = useState(() => false);

  const handleDayClick = useEvent((day, modifiers) => {
    // If date is disabled, just discard it
    if (modifiers.disabled) {
      return;
    }

    setExpanded(false);
    onDayClick(day);
  });

  const toggleExpanded = useEvent(() => {
    setExpanded((prev) => !prev);
  });

  const initialMonth = useMemo(() => {
    return (!!value && !!value.length ? value[0] : value) || new Date();
  }, [value]);

  return (
    <S.FormDatePicker>
      {loading && <LoadingContent placement="cover" />}

      {withInput && (
        <S.DateInput
          $expanded={expanded}
          onClick={toggleExpanded}
          $fullWidth={fullWidth}
        >
          {value ? formatDateExtended(value, true) : 'Select a date…'}
          <Icon type="calendar-24" />
        </S.DateInput>
      )}

      {(!withInput || expanded) && (
        <S.Picker
          $withInput={withInput}
          $expanded={expanded}
          $selectionDisabled={selectionDisabled && range}
        >
          <S.DayPicker
            {...remainingProps}
            $range={range}
            initialMonth={initialMonth}
            onDayClick={handleDayClick}
            onMonthChange={onMonthChange}
            selectedDays={value}
            disabledDays={disabledDays}
            weekdaysShort={['S', 'M', 'T', 'W', 'T', 'F', 'S']}
            navbarElement={(props) => {
              return (
                <S.CustomNavBar>
                  <S.CustomNavBarPrev
                    onClick={() => {
                      props.onPreviousClick();
                    }}
                  >
                    <Icon type="arrow-left-24" />
                  </S.CustomNavBarPrev>
                  <S.CustomNavBarNext
                    onClick={() => {
                      props.onNextClick();
                    }}
                  >
                    <Icon type="arrow-right-24" />
                  </S.CustomNavBarNext>
                </S.CustomNavBar>
              );
            }}
            renderDay={(day, modifiers) => {
              const { selected, singleDay } = modifiers;

              let active = selected && !selectionDisabled;
              if (range) {
                active = selected && singleDay && !selectionDisabled;
              }

              return <S.Day $active={active}>{day.getDate()}</S.Day>;
            }}
          />
        </S.Picker>
      )}
    </S.FormDatePicker>
  );
}

FormDatePicker.defaultProps = {
  selectionDisabled: false,
  fullWidth: false,
  withInput: false,
  value: null,
  disabledDays: [],
  onDayClick: () => {},
  onMonthChange: () => {},
};

const dateType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.instanceOf(Date),
  PropTypes.instanceOf(moment),
]);

FormDatePicker.propTypes = {
  selectionDisabled: PropTypes.bool,
  withInput: PropTypes.bool,
  value: PropTypes.oneOfType([
    dateType,
    PropTypes.arrayOf(dateType),
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        dateType,
        PropTypes.shape({
          from: dateType,
          to: dateType,
        }),
      ]),
    ),
  ]),
  disabledDays: PropTypes.array,
  onDayClick: PropTypes.func,
  onMonthChange: PropTypes.func,
};
