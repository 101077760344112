import PropTypes from 'prop-types';

import FluidSvg from '@/components/graphics/FluidSvg';
import Icon from '@/components/graphics/Icon';
import Image, {
  ImageFallbackRetryPlaceholder,
} from '@/components/graphics/Image';
import SvgUse from '@/components/graphics/SvgUse';
import Text from '@/components/text-headings/Text';

import * as S from './Avatar.styles';

/**
 * A image representing a user.
 */
const Avatar = ({ imageUrl, ...otherProps }) => {
  return (
    <S.Avatar {...otherProps}>
      <S.AvatarInner>
        {imageUrl && (
          <Image
            src={imageUrl}
            alt=""
            transform="square"
            renderErrorFallback={({ reload }) =>
              otherProps.mini ? (
                <Text>
                  <p>
                    <ImageFallbackRetryPlaceholder onClick={reload}>
                      <Icon type="reload-24" />
                    </ImageFallbackRetryPlaceholder>
                  </p>
                </Text>
              ) : (
                <Text>
                  <p>
                    <ImageFallbackRetryPlaceholder inline onClick={reload}>
                      <Icon type="reload-24" /> Reload
                    </ImageFallbackRetryPlaceholder>
                  </p>
                </Text>
              )
            }
          />
        )}
        {!imageUrl && (
          <FluidSvg percent={100} absolute>
            <SvgUse id="default-user" category="full" />
          </FluidSvg>
        )}
      </S.AvatarInner>
    </S.Avatar>
  );
};

Avatar.propTypes = {
  mini: PropTypes.bool,
  small: PropTypes.bool,
  imageUrl: PropTypes.string,
};

export default Avatar;
